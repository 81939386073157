import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Divider,
  SimpleGrid,
  Grid,
  GridItem,
  Container,
} from "@chakra-ui/react";

const OfficeHours = () => {
  return (
    <>
      <Flex  display={['none','none','none','block']} py={10} w="100%" >
        <Container maxW="container.lg">
          <Heading pb={5} fontWeight="semibold">
            Office Hours
          </Heading>
          <SimpleGrid fontSize="sm" columns={[7]}>
            <Flex
              w="100%"
              px={2}
            //  borderLeft="1px solid black"
              borderRight="1px solid black"
              flexDir="column"
            >
              <Text textAlign="center" fontWeight="bold">
                Monday
              </Text>
              <Text>8:30 am - 1:00pm</Text>
              <Text>3:00 pm - 6:00pm</Text>
            </Flex>
            <Flex px={2} borderRight="1px solid black" flexDir="column">
              <Text textAlign="center" fontWeight="bold">
                Tuesday
              </Text>
              <Text>8:30 am - 1:00pm</Text>
              <Text>3:00 pm - 6:00pm</Text>
            </Flex>
            <Flex px={2} borderRight="1px solid black" flexDir="column">
              <Text textAlign="center" fontWeight="bold">
                Wednesday
              </Text>
              <Text>8:30 am - 1:00pm</Text>
              <Text>3:00 pm - 6:00pm</Text>
            </Flex>
            <Flex px={2} borderRight="1px solid black" flexDir="column">
              <Text textAlign="center" fontWeight="bold">
                Thursday
              </Text>
              <Text>8:30 am - 1:00pm</Text>
              <Text>3:00 pm - 6:00pm</Text>
            </Flex>
            <Flex px={2} borderRight="1px solid black" flexDir="column">
              <Text textAlign="center" fontWeight="bold">
                Friday
              </Text>
              <Text>8:30 am - 1:00pm</Text>
              {/* <Text>8:30 am - 6:00pm</Text> */}
            </Flex>
            <Flex 
            px={2} 
            borderRight="1px solid black" 
            flexDir="column">
              <Text textAlign="center" fontWeight="bold">
                Saturday
              </Text>
              {/* <Text>8:30 am - 1:00pm</Text>
                    <Text>8:30 am - 6:00pm</Text> */}
              <Text textAlign="center">Closed</Text>
            </Flex>
            <Flex px={2} 
            // borderRight="1px solid black" 
            flexDir="column">
              <Text textAlign="center" fontWeight="bold">
                Sunday
              </Text>
              {/* <Text>8:30 am - 1:00pm</Text>
                    <Text>8:30 am - 6:00pm</Text> */}
              <Text textAlign="center">Closed</Text>
            </Flex>
          </SimpleGrid>
        </Container>
      </Flex>
      <Flex  display={['block','block','block','none']} py={10} w="100%" >
         <Container maxW="container.lg">
             <Heading pb={5} fontWeight="semibold">Office Hours</Heading>
             <Flex bg="gray.100" height="55px" border="1px solid black" justify="center" w="100%">
             <SimpleGrid  w="100%" columns={2}>
                 <Flex justify="center" align="center" borderRight="1px solid black">
                     <Text fontWeight="bold">Monday</Text>
                 </Flex>
                 <Flex flexDir="column" align="center" justify="center">
                     <Text>8:30am - 1:00pm</Text>
                     <Text>3:00pm - 6:00pm</Text>
                 </Flex>
             </SimpleGrid>
             </Flex>
             <Flex  height="55px" border="1px solid black" justify="center" w="100%">
             <SimpleGrid w="100%" columns={2}>
                 <Flex justify="center" align="center" borderRight="1px solid black">
                     <Text fontWeight="bold">Tuesday</Text>
                 </Flex>
                 <Flex flexDir="column" align="center" justify="center">
                     <Text>8:30am - 1:00pm</Text>
                     <Text>3:00pm - 6:00pm</Text>
                 </Flex>
             </SimpleGrid>
             </Flex>
             <Flex bg="gray.100" height="55px" border="1px solid black" justify="center" w="100%">
             <SimpleGrid w="100%" columns={2}>
                 <Flex justify="center" align="center" borderRight="1px solid black">
                     <Text fontWeight="bold">Wednesday</Text>
                 </Flex>
                 <Flex flexDir="column" align="center" justify="center">
                     <Text>8:30am - 1:00pm</Text>
                     <Text>3:00pm - 6:00pm</Text>
                 </Flex>
             </SimpleGrid>
             </Flex>
             <Flex  height="55px" border="1px solid black" justify="center" w="100%">
             <SimpleGrid w="100%" columns={2}>
                 <Flex justify="center" align="center" borderRight="1px solid black">
                     <Text fontWeight="bold">Thursday</Text>
                 </Flex>
                 <Flex flexDir="column" align="center" justify="center">
                     <Text>8:30am - 1:00pm</Text>
                     <Text>3:00pm - 6:00pm</Text>
                 </Flex>
             </SimpleGrid>
             </Flex>
             <Flex bg="gray.100" height="55px" border="1px solid black" justify="center" w="100%">
             <SimpleGrid w="100%" columns={2}>
                 <Flex justify="center" align="center" borderRight="1px solid black">
                     <Text fontWeight="bold">Friday</Text>
                 </Flex>
                 <Flex flexDir="column" align="center" justify="center">
                     <Text>8:30am - 1:00pm</Text>
                     
                 </Flex>
             </SimpleGrid>
             </Flex>
             <Flex height="55px" border="1px solid black" justify="center" w="100%">
             <SimpleGrid w="100%" columns={2}>
                 <Flex justify="center" align="center" borderRight="1px solid black">
                     <Text fontWeight="bold">Saturday</Text>
                 </Flex>
                 <Flex flexDir="column" align="center" justify="center">
                     <Text>Closed</Text>
                 </Flex>
             </SimpleGrid>
             </Flex>
             <Flex bg="gray.100" height="55px" border="1px solid black" justify="center" w="100%">
             <SimpleGrid w="100%" columns={2}>
                 <Flex justify="center" align="center" borderRight="1px solid black">
                     <Text fontWeight="bold">Sunday</Text>
                 </Flex>
                 <Flex flexDir="column" align="center" justify="center">
                     <Text>Closed</Text>
                     
                 </Flex>
             </SimpleGrid>
             </Flex>
         </Container>
      </Flex>
    </>
  );
};

export default OfficeHours;
