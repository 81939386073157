import { Box, BoxProps, Img, Text, Circle } from '@chakra-ui/react'
import * as React from 'react'
import Image from 'next/image'
import MyImage from '../../atoms/MyImage'
interface Props extends BoxProps {
  name: string
  jobTitle: string
  imageSrc: string
}

export const Quotee = (props: Props) => {
  const { name, jobTitle, imageSrc, ...boxProps } = props
  return (
    <Box {...boxProps}>
      <Circle overflow='hidden' m='0 auto'  pos='relative'  h='16' w='16'>
   <MyImage src={imageSrc} />
   </Circle>
      {/* <Img src={imageSrc} mx="auto" objectFit="cover" w="16" h="16" rounded="full" /> */}
      <Box mt="3">
        <Text color="gray.300" as="cite" fontStyle="normal" fontWeight="bold">
          {name}
        </Text>
        <Text fontSize="sm" color="gray.300">
          {jobTitle}
        </Text>
      </Box>
    </Box>
  )
}
