import React, { useEffect, useState } from "react";
//please work
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,

  Text,
  Link,

  Button,
  SimpleGrid,
  Box,

  useDisclosure,
  Popover, PopoverArrow, PopoverTrigger,PopoverBody,PopoverCloseButton,PopoverContent,PopoverHeader,
} from "@chakra-ui/react";
// import {
//   FaCalendarCheck,
//   FaMapMarkerAlt,
//   FaNotesMedical,
//   FaPhoneAlt,
// } from "react-icons/fa";
import { FaCalendarCheck } from '@react-icons/all-files/fa/FaCalendarCheck';
import { FaMapMarkerAlt } from '@react-icons/all-files/fa/FaMapMarkerAlt';
import { FaNotesMedical } from '@react-icons/all-files/fa/FaNotesMedical';
import { FaPhoneAlt } from '@react-icons/all-files/fa/FaPhoneAlt';
import Image from 'next/image'
import * as gtag from '../../lib/gtag'
import { useRouter } from "next/router";
import useStore from "../../store";
export default function Lightbox() {
  //* Global State Getters and Setters  *\\

  const modalRejected = useStore(state => state.modalRejected)
  const toggleModalRejected = useStore(state => state.toggleModalRejected)
  const hasBooked = useStore(state => state.hasBooked)
  const toggleHasBooked = useStore(state => state.toggleHasBooked)
  const currentUser = useStore(state => state.currentUser)


 

  // useEffect(() => {
  //   if(window !== 'undefined') {
  //     if(hasBooked === true) {
  //       gtag.event({
  //         action: 'generate_lead',
  //         category: "engagement",
  //         label: "appointmentFormLead",
  //         value: 1
  //       })
  //     }
  //   }
  // }, [hasBooked])




  


  //* End Global State Management *\\
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rejected, setRejected] = useState();
  const [acceptedParams, setAcceptedParams] = useState({accepted: false, destination: null})
  const router = useRouter()


  const rejectModal = () => {
    setRejected(true);
    onClose();
  };

  const acceptModalForAppointment = () => {
    setAcceptedParams({accepted: true, destination: '/appointment'})
    router.push('/appointment')
  }

  const acceptModalForOnlineForms = () => {
    setAcceptedParams({accepted: true, destination: 'MyChiroTouch Forms'})
  }

  

 
  useEffect(() => {
    if (!rejected) {
      if(!acceptedParams.accepted) {
      setTimeout(() => {
        onOpen();
      }, 150000);
    }
    }
  }, [rejected, acceptedParams]);
//go!
  return (
    <>
      <Modal bg={"rgb(0,0,0,0.3)"} blockScrollOnMount={false} onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent bg={"blue.300"}>
          <ModalHeader textAlign="center">Glad to see you here!</ModalHeader>
          <ModalCloseButton onClick={rejectModal} />
          <ModalBody>
            <Text fontWeight="bold" textAlign="center">
              How can we help you today?
            </Text>
            <Box py={5}>
              <Text pb={5} fontStyle={"italic"} textAlign="center">
                I need to...
              </Text>
              <SimpleGrid spacing={3} columns={1}>
                <Button
                  onClick={() => router.push('/appointment')}
                  _hover={{ bg: "purple.600" }}
                  color="white"
                  bg="purple.500"
                  id="modalAppt"
                  rightIcon={<FaCalendarCheck />}
                >
                  Book an Appointment
                </Button>{" "}
              <Link textDecoration="none" _hover={{textDecor:'none'}} target="_blank" rel="noopener noreferrer"  w='100%' href="https://intake.mychirotouch.com/en-US/?clinic=BC0229">
                <Button
                w='100%'
                onClick={rejectModal}
                  _hover={{ bg: "purple.600" }}
                  color="white"
                  bg="purple.500"
                  id="modalForms"
                  rightIcon={<FaNotesMedical />}
                >
                  Complete my patient forms
                </Button>
               </Link>
               <Link w='100%' href="tel:(803) 520-0084">
                <Button
                  _hover={{ bg: "purple.600" }}
                  color="white"
                  w='100%'
                  bg="purple.500"
                  id="modalPhone"
                  rightIcon={<FaPhoneAlt />}
                >
                  Call the Doctor
                </Button></Link>
                <Popover>
  <PopoverTrigger>
  <Button
                  _hover={{ bg: "purple.600" }}
                  color="white"
                  bg="purple.500"
                  id="modalMap"
                  rightIcon={<FaMapMarkerAlt />}
                >
                  Get Directions
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Which app would you like to use?</PopoverHeader>
                <PopoverBody w='100%'>
                  <SimpleGrid columnGap={3} alignItems='space-evenly' justifyContent='space-evenly' justifyItems='space-evenly'w='100%'columns={3}>
                  <a target="none" href="https://maps.apple.com/?address=5076%20Sunset%20Blvd,%20Unit%20C,%20Lexington,%20SC%20%2029072,%20United%20States&auid=17030292374442269028&ll=34.009352,-81.193745&lsp=9902&q=Jason%20Mubarak%20-%20Midlands%20Spine%20Center&_ext=CjIKBQgEEOIBCgQIBRADCgQIBhByCgQIChAACgQIUhAECgQIVRAACgQIWRAGCgUIpAEQARImKQ8ni0efAEFAMbGcExe/TFTAOY38sKPFAUFAQeFFGooNTFTAUAM%3Dhttps://maps.apple.com/?address=5076%20Sunset%20Blvd,%20Unit%20C,%20Lexington,%20SC%20%2029072,%20United%20States&auid=17030292374442269028&ll=34.009352,-81.193745&lsp=9902&q=Jason%20Mubarak%20-%20Midlands%20Spine%20Center&_ext=CjIKBQgEEOIBCgQIBRADCgQIBhByCgQIChAACgQIUhAECgQIVRAACgQIWRAGCgUIpAEQARImKQ8ni0efAEFAMbGcExe/TFTAOY38sKPFAUFAQeFFGooNTFTAUAM%3D">   <SimpleGrid columns={1}>
                   <Image src='/AppleMaps.png' height={512} width={512} />
                    
                    </SimpleGrid></a>
                    <a target="none" href="https://www.google.com/maps/place/5076+Sunset+Blvd,+Lexington,+SC+29072/@34.0093822,-81.195933,17z/data=!3m1!4b1!4m5!3m4!1s0x88f8978ed6b71bab:0x1923b60c807a178c!8m2!3d34.0093778!4d-81.193739">    <SimpleGrid columns={1}>
                      <Image src='/GoogleMaps.png' height={461} width={512} />
                    
                    </SimpleGrid></a>
  
                    <a target="none" href="https://www.waze.com/ul?q=midlands%20spine%20center%2029072">     <SimpleGrid columns={1}>
                    <Image src='/WazeIcon2.png' width={400} height={400} />
                    </SimpleGrid></a>
                  </SimpleGrid>
                  {/* <Flex color='blue.300' flexDir='row' justify='space-around'>
                    <Icon w='100px' h='100px' as={WazeIcon} />
                  
                   <Image src='/AppleMaps.png' width={100} height={100} objectFit='cover' objectPosition='center' />
                  </Flex> */}
                </PopoverBody>
              </PopoverContent>
              </Popover>

              </SimpleGrid>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={rejectModal}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
