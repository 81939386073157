import { Box, Circle, Flex, HStack, Text, useColorModeValue, Heading, Icon, IconButton} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { Quotee } from './quotee'
import { QuoteIcon } from './quoteicon'
// import { FaChevronLeft, FaChevronRight } from '@react-icons/all-files/fa';
import { FaChevronLeft } from '@react-icons/all-files/fa/FaChevronLeft';
import {FaChevronRight} from '@react-icons/all-files/fa/FaChevronRight';




export const Testimonials = () => {

    let test1;
    let test2;
    let test3;

    
 

    

    const testimonials = [
        test1 = {
          name: "Bob Horton",
          jobTitle: "Patient",
          imageSrc: "/horton.jpg",
          review: "I always get seen by Dr Mubarak at my appointment time and he has given me help and insight on my neck issue that I didn’t have before our visits. His easy going personality also makes it an enjoyable few minutes at his office."
        },
        test2 = {
            name: "Faithe Dixon",
            jobTitle: "Patient",
            imageSrc: "/dixon.jpg",
            review: "Thorough consultation, great service, nice staff!"
        },
        test3 = {
            name: "Tom Touchberry",
            jobTitle: "Patient",
            imageSrc: "/touchberry.jpg",
            review: "I highly recommend Midland's Spine Center and Dr. Jason Mubarak!  He helped me recover from chronic back problems, vertigo, and helped me improve my health greatly even as I grow older.  And he genuinely cares about his patients.  He's in it to help people first and one visit to his office you see that!"
        }
    ]
    const [x, setX] = useState(0)
    const [testimonial, setTestimonial] = useState(testimonials[x])
    

    const slideForward = () => {
        if(x === 0) {
            setX(1)
            setTestimonial(testimonials[1])
        }
        if(x === 1) {
            setX(2)
            setTestimonial(testimonials[2])
        }
        if(x === 2) {
            setX(0)
            setTestimonial(testimonials[0])
        }
    }
    
    const slideBackward = () => {
        if(x === 0) {
            setX(2)
            setTestimonial(testimonials[2])
        }
        if(x === 1) {
            setX(0)
            setTestimonial(testimonials[0])
            
        }
        if(x === 2) {
            setX(1)
            setTestimonial(testimonials[1])
        }
    }

    useEffect(() => {
       
    }, [testimonial])
 
    return(
        <Box as="section" bg="brand.200">
           
        <Box maxW="3xl" mx="auto" px={{ base: '6', md: '8' }} pt="12" pb="16">
          <Flex direction="column" align="center" textAlign="center">
          <Heading as="h2" fontSize="5xl" color="gray.300" fontWeight="thin">Testimonials</Heading>
          <Text color="gray.400" fontSize="3xl">Reviews By Our Satisfied Patients</Text>
            <QuoteIcon
              color={useColorModeValue('gray.300', 'gray.600')}
              fontSize={{ base: '3xl', md: '6xl' }}
            />
            <Text fontSize={{ base: 'xl', md: '2xl' }} color="white" fontWeight="medium" mt="6">
              &lsquo;{testimonial.review}&rsquo;
            </Text>
            <Quotee 
                
                name={testimonial.name}
                jobTitle={testimonial.jobTitle}
                imageSrc={testimonial.imageSrc}
                mt="8"
                />
            {/* <Quotee
              name="Marrie Jones"
              jobTitle="Marketing Ads Strategist"
              imageSrc="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixid=MXwxMjA3fDB8MHxzZWFyY2h8OTN8fGxhZHklMjBoZWFkc2hvdCUyMHNtaWxpbmd8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
              mt="8"
            /> */}
          </Flex>
          <HStack justify="center" spacing="4" mt="8" color={useColorModeValue('gray.300', 'gray.600')}>
              <IconButton bg={"transparent"} _focus={{bg:"blackAlpha.100"}} _active={{bg:"blackAlpha.500"}} _hover={{bg:"rgb(0,0,0,0.3)"}} onClick={slideBackward} aria-label="previous review" icon={<FaChevronLeft />} />
            <Circle transition=".2s ease-in" w={x === 0 ? "3" : "2"} h={x === 0 ? "3" : "2"} bg={x === 0 ? "blue.500" : "currentColor"} />
            <Circle  transition=".2s ease-in" w={x === 1 ? "3" : "2"} h={x === 1 ? "3" : "2"} bg={x === 1 ? "blue.500" : "currentColor"} />
            <Circle  transition=".2s ease-in" w={x === 2 ? "3" : 2} h={x === 2 ? "3" : "2"} bg={x === 2 ? "blue.500" : "currentColor"} /> 
            <IconButton bg={"transparent"} _focus={{bg:"blackAlpha.500"}} _hover={{bg:"rgb(0,0,0,0.3)"}} onClick={slideForward} icon={<FaChevronRight />} aria-label="next review" />

          </HStack>
        </Box>
      </Box>
    )
}
 
