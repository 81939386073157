import { Box, Container, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import Image from 'next/image';
import format from 'date-fns/format'
import React from 'react';
import useSWR from 'swr';
import ChakraNextLink from '../atoms/ChakraNextLink';
import MyImage from '../atoms/MyImage';
const FeaturedPosts = (props) => {
    const fetcher = (url) => fetch(url).then((res) => res.json());
    // const { data } = useSWR('/api/blog', fetcher, { 
    //     onSuccess: (e) => console.log(`successful blog fetch`,e)
    // })const data = { props: {
      const dataReducOne = props.data;
      const dataReducTwo = dataReducOne.data;
      const { posts } = dataReducTwo
      const data = posts
  

    console.log(`FEATURED POSTS PROPS`, props)
    console.log(`FEATURED POSTS DATA`, data)

    const formatPostDate = (date) => {
        const myDate = new Date(date)
        const formattedDate = format(myDate, 'MM/dd/yyyy')
        return formattedDate
    }

  
  return (
  <Box color='white' bg='brand.400' w='100%' py={5}>
      <Container maxW='container.lg'>
          <Heading color='black'  as="h2" textAlign='center' fontWeight='hairline' textDecor='underline' py={5}>Recent Posts</Heading>
          {data ? 
          <SimpleGrid columns={1}>
              <Box pb={1} w='100%' textAlign='center' fontSize='2xl'>
              <Box pos='relative' py={0} w='100%' >
                  <Image height={1080} width={1920} src={data?.edges[0].node.featuredImage.node.sourceUrl} alt={data?.edges[0].node.title} objectFit='cover' />
                {/* <MyImage src={data?.edges[0].node.featuredImage.node.sourceUrl} /> */}
              
              </Box>
            <Box py={3}>
           <ChakraNextLink textAlign='left' fontWeight='bold'  href={`/blog/posts/${data?.edges[0].node.slug}`}>{data?.edges[0].node.title}</ChakraNextLink>
           <br/>
           <Text fontSize='sm'>  {formatPostDate(data?.edges[0].node.date)}</Text></Box>
              </Box>
              <SimpleGrid gap={3} alignItems='start' justifyItems='space-between' w='100%' columns={2}>
                <Flex flexDir='column' align='center' justify='start' w='100%' textAlign='center'>
                <Box  pb={1} pos='relative' w='100%' >
                <Image height={1080} width={1920} src={data?.edges[1].node.featuredImage.node.sourceUrl} alt={data?.edges[0].node.title} objectFit='cover' />
                </Box>
                <Box py={3}>
           <ChakraNextLink fontWeight='bold'  href={`/blog/posts/${data?.edges[1].node.slug}`}>{data?.edges[1].node.title}</ChakraNextLink>
           <br/>
           <Text fontSize='sm'>  {formatPostDate(data?.edges[1].node.date)}</Text></Box>
                </Flex>
                <Flex flexDir='column' justify='end' align='center' w='100%' textAlign='center'>
                <Box pb={1} pos='relative' w='100%' >
                <Image height={1080} width={1920} src={data?.edges[2].node.featuredImage.node.sourceUrl} alt={data?.edges[0].node.title} objectFit='cover' />
                </Box>
                <Box py={3}>
           <ChakraNextLink fontWeight='bold'  href={`/blog/posts/${data?.edges[2].node.slug}`}>{data?.edges[2].node.title}</ChakraNextLink>
           <br/>
           <Text fontSize='sm'>  {formatPostDate(data?.edges[2].node.date)}</Text></Box>
                </Flex>
              </SimpleGrid>
          </SimpleGrid>
            : null}
      </Container>
  </Box>
  
    );
};

export default FeaturedPosts;
