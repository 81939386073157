import React, { useEffect, useState, useRef, Suspense } from "react";
import Head from "next/head";
import Image from "next/image";
import { Header } from "../components/header";
import HomeHero from "../components/Heroes/HomeHero";
import Link from "next/link";
import ReactPlayer from "react-player/lazy";
import CurvedSpine from "../public/curved-spine.jpeg";
import styles from "../styles/Home.module.css";
import HeroMain from "/public/healthy-spine.jpeg";
import Lightbox from "../components/lightbox";
import ReactVisibilitySensor from "react-visibility-sensor";

import {
  Flex,
  Box,
  Container,
  Heading,
  Text,
  Button,
  Grid,
  GridItem,
  Image as ChakImg,
  AspectRatio,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import Linker from "../components/atoms/Linker";
import { Testimonials } from "../components/molecules/testimonials/testimonials";
// import NewsletterSignup from "../components/molecules/newsletter-signup/NewsletterSignup";
// import FeaturedArticles from "../components/molecules/featured-articles/FeaturedArticles";
// import RequestAppointment from "../components/cta/RequestAppointment";
// import ContactUs from "../components/cta/ContactUs";
import OfficeHours from "../components/molecules/office-hours/OfficeHours";
// import Schema from "../components/schema";
// import Script from "next/script";
// import { useJsApiLoader } from "@react-google-maps/api";
// import { Loader } from "@googlemaps/js-api-loader";

import dynamic from "next/dynamic";
import { FaCalendarCheck } from "@react-icons/all-files/fa/FaCalendarCheck";
import Layout from "../components/layout";
import AppointmentRequestForm from "../components/cta/AppointmentRequestForm";
import FeaturedPosts from "../components/FeaturedPosts";
import MyImage from "../components/atoms/MyImage";

const LazyPlayer = dynamic(() => import("react-player/lazy"), {
});

const LazyFeaturedPosts = dynamic(() => import("../components/FeaturedPosts"), {
 
});


export default function Home({data}) {
 const myData = JSON.parse(data)
 console.log(myData)
 const postData = myData.data
console.log(`myPostData`, postData)
  // useEffect(() => {
  //  if(window !== 'undefined')
  //  window.onscroll = function(ev) {
  //    if((window.innerHeight + window.scrollY) >=document.body.offsetHeight) {
  //      alert('bottom!')
  //    }
  //  }
  // }, [])
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: settings?.googleMaps?.apiKey
  // })
  // const Map = dynamic(() => import("../components/molecules/location-map/WorkingMap"), {
  //   loading: () => <Flex h="100vh" w="100%"><Spinner size="xl" /></Flex>,
  //   ssr: false
  // })

  const router = useRouter();
  const location = {
    address: "Midlands Spine Center, 5076 Sunset Blvd, Lexington, SC 29072",
    lat: 34.009349999999998,
    lng: -81.193740000000005,
  };
  //  if(isLoaded) {
  return (
    <>
      {/* <Script
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCID_-TNomGR_Au7_gvv8r7P11ldBHpubU&callback=initMap"
        strategy="beforeInteractive"
      /> */}

      <Lightbox />
      <Layout
        title="Chiropractor in Lexington, SC | Midlands Spine Center"
        description="Trusted, compassionate, and experienced chiropractic  and pain management care. Book online now with Dr. Jason Mubarak, or call us at 803-520-0084."
      >
        <Flex
          bg="rgb(0,0,0,0.4)"
          // bgImage="/midlands-spine-home-img-square.png"
          // bgRepeat="no-repeat"
          // h={["25vh", "25vh", "87vh"]}
          // bgSize="cover"
          // bgClip="padding-box"
          // bgPos="center"
          w="100%"
          flexDir="column"
          justify="center"
        >
          <Box
            order={[2, 2, 2, 1]}
            position="relative"
            width="100%"
            minH={["25vh", "25vh", "40vh", "87vh"]}
          >
            <Box>
              <Image
                placeholder="blur"
                src={HeroMain}
                layout="fill"
                objectFit="cover"
                objectPosition="80% 60%"
              />
            </Box>
            <Flex
              color="white"
              zIndex={2}
              pos="absolute"
              align="center"
              justify="center"
              bg="blackAlpha.500"
              w="100%"
              h="100%"
            >
              {""}
              <Container
                display={["none", "none", "none", "block"]}
                py={5}
                bg={["transparent", null, null, "blackAlpha.600"]}
                maxW="container.md"
              >
                <Box>
                  <Heading
                    display={["none", "none", "none", "block"]}
                    fontWeight="normal"
                    lineHeight={1.5}
                    fontSize={["2xl"]}
                    textAlign="center"
                  >
                    <strong>You've Only Got One Spine.</strong> <br />
                    Take Great Care Of It With Supportive Chiropractic Care.
                  </Heading>
                </Box>
                <Flex w="100%" align="stretch" justify="center">
                  <Button
                    onClick={() => router.push("/appointment")}
                    _hover={{ bg: "blackAlpha.300" }}
                    color="white"
                    alignSelf="stretch"
                    mt={5}
                    bg="transparent"
                    border="1px solid white"
                    borderRadius="none"
                    size="lg"
                    fontSize="2xl"
                    pt={5}
                    pb={5}
                    rightIcon={<FaCalendarCheck />}
                  >
                    REQUEST APPOINTMENT
                  </Button>
                </Flex>
                <Flex
                  flexDir="column"
                  alignSelf="center"
                  alignContent="center"
                  align="center"
                ></Flex>
              </Container>
            </Flex>
            <Box bg="black" opacity="0.35" pos="absolute" w="100%" h="100%">
              {" "}
            </Box>
          </Box>
          {/* <Flex
          flexDir="column"
          w="100%"
          h={["25vh", "25vh", "87vh"]}
          bg="blackAlpha.500"
          justify="center"
          align="center"
        >
          <Container
            display={["none", "none", "flex"]}
            maxW="container.md"
            centerContent
          >
            <Heading
              w="100%"
              textAlign="center"
              fontWeight="hairline"
              py={2}
              color="white"
            >
              SUPPORTING SPINAL HEALTH
            </Heading>
            <Text py={2} textAlign="center" fontWeight="semibold" color="white">
              You've Only Got One Spine. Take Great Care Of It With Supportive
              Chiropractive Care.
            </Text>
            <Button
              onClick={() => router.push("/appointment")}
              _hover={{ bg: "blackAlpha.300" }}
              color="white"
              alignSelf="stretch"
              mt={5}
              bg="transparent"
              border="1px solid white"
              borderRadius="none"
              size="lg"
              fontSize="2xl"
            >
              REQUEST APPOINTMENT
            </Button>
          </Container>
        </Flex> */}
        </Flex>
        <Flex
          order={[1, 1, 1, 2]}
          color="white"
          bg="brand.300"
          py={5}
          w="100%"
          display={["flex", "flex", "flex", "none"]}
        >
          <Container maxW="container.md" centerContent>
            <Heading
              textAlign="center"
              fontWeight="hairline"
              py={2}
              color="white"
            >
              SUPPORTING SPINAL HEALTH
            </Heading>
            <Text
              py={2}
              textAlign="center"
              fontSize="sm"
              fontWeight="semibold"
              color="white"
            >
              You've Only Got One Spine. Take Great Care Of It With Supportive
              Chiropractive Care.
            </Text>
            <Button
              onClick={() => router.push("/appointment")}
              _hover={{ bg: "blackAlpha.300" }}
              color="white"
              alignSelf="stretch"
              mt={5}
              bg="transparent"
              border="1px solid white"
              borderRadius="none"
              size="lg"
              fontSize="xl"
              pt={5}
              pb={5}
              rightIcon={<FaCalendarCheck />}
            >
              REQUEST APPOINTMENT
            </Button>
          </Container>
        </Flex>
        {/* <ActionBar/> */}
        {/** BODY (home) SECTION */}
        <Flex py={5} flexDir="column" w="100%">
          <Container py={5} maxW="container.lg">
            <Grid
              templateColumns="repeat(5, 1fr)"
              rowGap={3}
              templateRows={"repeat(1, 1fr)"}
              gap={6}
            >
              <GridItem order={[1]} colSpan={[5, 5, 3]}>
                <Flex flexDir="column">
                  <Heading pb={5} textAlign="center" fontSize="3xl">
                    Welcome to Midlands Spine Center
                  </Heading>
                  <Text py={[0, 0, 3]}>
                    Dr. Jason Mubarak is a chiropractor serving Lexington and
                    the surrounding area. Our chiropractor and the rest of the
                    welcoming team at Midlands Spine Center are committed to
                    providing chiropractic solutions to address your unique
                    needs, whether you are experiencing back pain, neck pain,
                    headaches, or even muscular tightness and tension. You may
                    be searching for pain relief after an accident, experiencing
                    an injury, or if you suffer from a specific condition like
                    chronic back pain or a spinal condition. Even if you are
                    looking to improve your overall health, our chiropractor can
                    help you attain your everyday wellness goals! Visit our
                    testimonials page to find out what our patients are saying
                    about our Lexington chiropractor and our chiropractic care
                    plans.
                  </Text>
                </Flex>
              </GridItem>
              <GridItem order={[2]} rowSpan={1} colSpan={[5, 5, 2]}>
                <Flex w="100%" h="100%" justify="center" align="center">
                  <Image src={CurvedSpine} />
                </Flex>
              </GridItem>

              <GridItem
                order={[4, 4, 3]}
                py={5}
                alignItems="center"
                colSpan={[5, 5, 2]}
              >
                <Flex
                  bg="blackAlpha.50"
                  justify="center"
                  align="center"
                  w="100%"
                  h="100%"
                >
                
     
                       {/* <Container boxShadow="dark-lg" h="200px">
                         <Suspense fallback={`loading...`}>
                           <LazyPlayer
                             width="100%"
                             height="100%"
                             url="https://www.youtube.com/embed/Nk7zGovVDzo"
                           />
                         </Suspense>
                       </Container> */}
               
              
                    {/* <AspectRatio maxW='560' ratio={16 / 9}>  */}
                    <ReactVisibilitySensor>
                      {({ isVisible }) => (
                        isVisible ? (
                  <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Nk7zGovVDzo?enablejsapi=1"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                        ) : (
                          <Box w='100%' h='315px' bg='blackAlpha.50'>What is chiropractic? (video)</Box>
                        )
                        )}
                </ReactVisibilitySensor>
                   {/* </AspectRatio> */}
                </Flex>
              </GridItem>
              <GridItem order={[3, 3, 4]} colSpan={[5, 5, 3]}>
                <Flex w="100%" align="center" justify="center">
                  <Text>
                    At your first visit to Midlands Spine Center, Dr. Jason
                    Mubarak will explain how chiropractic care works and give
                    you a full evaluation to see if our chiropractic solutions
                    are a good fit to address your needs. If there is a good
                    fit, we can customize a plan of chiropractic care for your
                    condition and health goals.
                    <br />
                    <br />
                    If you have not been to a chiropractor before and want to
                    find out more, please email us or call (803) 520-0084 to
                    receive the answers to your questions from our experienced
                    team. We also welcome referrals, so we invite you to share
                    our chiropractic and wellness information with your friends
                    and loved ones.
                    <br />
                    <br />
                    If you would like to stay informed about the latest in
                    chiropractic care or if you want to start receiving free
                    articles about health and wellness news, subscribe to our
                    bi-weekly newsletter, even if you are not a patient. We also
                    offer a community content area, which includes wellness
                    articles and other healthcare resources.
                  </Text>
                </Flex>
              </GridItem>
            </Grid>
          </Container>
        </Flex>
        {/**  MEET TEAM SECTION */}
        <Flex bg={"gray.200"} py={8} w="100%">
          <Container maxW="container.lg">
            <Grid
              py={3}
              templateRows={"repeat(1,1fr)"}
              templateColumns={"repeat(5, 1fr)"}
            >
              <GridItem colSpan={[5, 5, 2]}>
                <Flex
                  w="100%"
                  justify="center"
                  align={["center", null, null, "flex-start"]}
                  flexDir="column"
                >
                  <Heading as="h2" fontWeight="hairline">
                    Meet the Team
                  </Heading>
                  <Text fontWeight="bold" fontSize="2xl">
                    Learn Who We Are
                  </Text>
                </Flex>
              </GridItem>
            </Grid>
            <Grid
              gap={3}
              templateRows={["repeat(3, 1fr)"]}
              templateColumns={"repeat(5,1fr)"}
            >
              <GridItem colSpan={[5, 5, 2]} rowSpan={3}>
                <Flex
                  w="100%"
                  align="center"
                  justify={["center", null, null, "flex-start"]}
                >
                  <Box boxShadow="dark-lg" h="sm" w="sm" pos="relative">
                    <MyImage src="/dr_jason_mubarak_square.jpg" />
                  </Box>
                </Flex>
              </GridItem>

              <GridItem colSpan={[5, 5, 3]} rowSpan={1}>
                <Heading
                  as="h3"
                  py={[5, null, 0]}
                  textAlign={["center", "center", "left"]}
                  fontWeight="semibold"
                >
                  Dr. Jason Mubarak
                </Heading>
              </GridItem>
              <GridItem colSpan={[5, 5, 3]} rowSpan={2}>
                <Flex align={["center"]} flexDir="column" w="100%">
                  <Text pb={3}>
                    Dr. Mubarak always wanted to help people; his goal was to
                    become a medical doctor. When he was 20 years old, he was
                    moving a desk and developed severe back pain and right
                    sciatic pain. After medical care was unsuccessful, he
                    decided to try Chiropractic care. After receiving
                    Chiropractic care, he began feeling amazing; his back and
                    sciatic leg pain no longer bothered him.
                  </Text>
                  <Linker
                    href="/meet-the-team"
                    title="READ MORE"
                    inactiveColor="blue.400"
                  />
                </Flex>
              </GridItem>
            </Grid>
          </Container>
        </Flex>
        <Testimonials />
        {/* <NewsletterSignup /> */}
        <Flex w="100%" justify="center" align="center">
          {/* <FeaturedArticles /> */}
       
            <FeaturedPosts data={myData} />
          
        </Flex>
        {/* <RequestAppointment /> */}
        <Box bg="brand.300" id="book">
          <Heading color="white" py={3} textAlign={"center"}>
            Request Appointment
          </Heading>
          <AppointmentRequestForm />
          {/* <ContactUs /> */}
        </Box>
        <OfficeHours />
      </Layout>
      {/* <LocationMap /> */}

      {/* <Map location={location} zoomLevel={16} /> */}
      {/* <Map /> */}
    </>
  );
  // ) } else return (
  //   <Flex justify="center" w="100%" h="100vh"><Spinner size="xl" /></Flex>
  // )
}

export async function getStaticProps(){
  const threePostsForHomeQuery = `
  query AllPosts {
    posts(first: 3, where: { orderby: { field: DATE, order: DESC } }) {
      edges {
        node {
          title
          excerpt
          slug
          date
          featuredImage {
            node {
              sourceUrl
              altText
            }
          }
          author {
            node {
              name
              firstName
              lastName
              avatar {
                url
              }
            }
          }
        }
      }
    }
  }
`
async function fetchAPI(query, { variables } = {}) {
  const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json' }
  const API_URL = 'https://msc-admin.sublimesolutions.dev/index.php?graphql/'
  const res = await fetch(API_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query,
      variables,
    }),
  })

  const json = await res.json()
  if (json.errors) {
    console.error(json.errors)
    throw new Error('Failed to fetch API')
  }
  return json
}



const readData = await fetchAPI(threePostsForHomeQuery)
const data = JSON.stringify(readData)

console.log(`read`,readData)
console.log(`fin`,data)



  
  return {
    props: {
      data
    }
  }
}