import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Head from "next/head";

const Map = dynamic(() => import("../molecules/location-map/MapWrapper"), {
  loading: () => {
    return <Flex fontSize="8xl">Loading</Flex>;
  },
});

const Layout = ({
  children,
  title = "Lexington, SC Chiropractor 29072 | Midlands Spine Center | Dr. Jason Mubarak",
  description = "Receive trusted, compassionate, and experienced chiropractic care in Lexington, SC with Dr. Jason Mubarak. Book an appointment online or call us today at (803) 520-0084.",
  image = "https://midlandsspinecenter.com/logo.png",
  ogType = "website",
  schemaType = "MedicalBusiness",
  keywords = "chiropractor near me, chiropractor lexington sc, disc bulge treatment, slipped disc treatment, herniated disc treatment, chiropractic lexington sc",
  post,

}) => {
  const pTitle = `${title}`
  const pSchemaType = `${schemaType}`
  const pKeywords = `${keywords}`
  const pDescription = `${description}`
  const pImage = `${image}`



  const CANONICAL_DOMAIN = "https://midlandsspinecenter.com";
  const router = useRouter();
  const _pathSliceLength = Math.min.apply(Math, [
    router.asPath.indexOf("?") > 0
      ? router.asPath.indexOf("?")
      : router.asPath.length,
    router.asPath.indexOf("#") > 0
      ? router.asPath.indexOf("#")
      : router.asPath.length,
  ]);
  const canonicalURL =
    CANONICAL_DOMAIN + router.asPath.substring(0, _pathSliceLength);

  const baseUrl = "https://midlandsspinecenter.com";

  return (
    <Box>
      {!post ?
      <Head>
        
        <title>{title}</title>
        <meta property="og:type" content="article" />
        <meta name="twitter:site" content="@MidlandsSpine" />
        <meta name="twitter:card" content="summary" />
        <meta property="og:title" content={pTitle} />
        
        <meta
          name="robots"
          content="index, follow" />
        <meta name="description"  content={pDescription} />
        <meta property="og:description" content={pDescription} />
        <meta name="image"  content={pImage} />
        <meta property="og:image" content={pImage} />
        {/* <meta name={name ? name : title} /> */}
        <meta property="og:locale" content="en_US" />
        <link rel="canonical" href={canonicalURL} />
        <meta property="og:keywords" content={pKeywords} />
        <meta property="og:type" content={router.asPath === '/' ? 'business.business' : 'website'} />
        <meta property="og:url" content={canonicalURL} />
        
        <script type="application/ld+json" dangerouslySetInnerHTML={{__html: `
      {
        "@context": [
          "http://schema.org",
          {
            "language": "en-us"
          }
        ],
        "@type": "MedicalBusiness",
        "url": "https://midlandsspinecenter.com/",
        "name": "${pTitle}",
        "description": "${pDescription}",
        "image": "${pImage}",
        "telephone": "(803) 520-0084",
        "email": "drjasonmubarak@midlandsspinecenter.com",
        "priceRange": "Call for pricing options",
        "slogan": "You Only Have One Spine. Take Great Care Of It With Supportive Chiropractive Care.",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Lexington",
          "addressRegion": "SC",
          "postalCode": "29072",
          "streetAddress": "5076 Sunset Blvd Unit C",
          "addressCountry": "US"
        },
        "sameAs": [
          "https://twitter.com/MidlandsSpine",
          "https://www.facebook.com/MidlandsSpineCenter",
          "https://www.instagram.com/drjasonmubarak"
        ],
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": 34.009349999999998,
          "longitude": -81.193740000000005
        },
        "logo": "https://midlandsspinecenter.com/logo.png"
      }
      
      `}} />
        {/* <Script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
      {
        
      }
      `,
          }}
        /> */}
      </Head> : <></>}

      {children}
        <Map  />
      
      {/* <Map /> */}
    </Box>
  );
};

export default Layout;
