import NextImage from 'next/image';
import React from 'react';
import styled from 'styled-components'

const ImageContainer = styled.div`
  > div {
    &:first-of-type {
      position: unset !important;
    }
    ${({ height }) => height && `max-height: ${height}`};
    ${({ width }) => width && `width: ${width}`};
  }
`;
const BaseImage = styled(NextImage)`
  object-fit: cover;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  width: ${({ color }) => color};
  text-decoration: none;
`;

const MyImage = (props) => {
  const { width, height, maxHeight, maxWidth, children, ...rest } = props;
  return (
    <ImageContainer width={width} height={height} maxHeight={maxHeight} maxWidth={maxWidth}>
      <BaseImage layout="fill" {...rest} objectFit="cover" objectPosition="center" />
      {children && <ImageOverlay>{children}</ImageOverlay>}
    </ImageContainer>
  );
};

export default MyImage;